// Forms Multi Select

import 'select2';
import 'bootstrap-multiselect';

$(document).ready(() => {
	$(".multiselect-dropdown").each(function() {
		let placeholder = $(this).data('placeholder') || 'Select an option';
		$(this).select2({
	        theme: "bootstrap4",
	        placeholder: placeholder
	    });
	});

	$(".select-dropdown").each(function() {
		let placeholder = $(this).data('placeholder') || 'Select an option';
		$(this).select2({
			theme: "bootstrap4",
			placeholder: placeholder
		});
	});
});