// Forms Textarea Autosize

import 'textarea-autosize';

$(document).ready(() => {

    setTimeout(function() {
        $('textarea.autosize-input').textareaAutoSize();
    }, 1500);

});