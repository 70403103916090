const nl = require('./i18n/nl');
const en = require('./i18n/en');
let locales = {'nl': nl, 'en': en};

global.i18n = function(locale) {
	let messages = locales[locale];
	let i18n = {
				getMessage: function(key) {
					if (messages) {
						let message = messages[key];
						if (message) {
							for (var i = 1; i < arguments.length; i++) {
								message = message.replace('{' + (i-1) + '}', arguments[i]);
							}
							return message;
						} 
					}
					return "??_".concat(key, "_", locale, "_??");
				}
	}
	return i18n;
}