import Cookies from 'js-cookie';

(function($)
{
	var defaults = {		
		delay: 300, //seconds
		max: 10,
		loadingClass: 'flex-loading'
	};
	
	var globallyDisabled = false;

	function getUserOptions(id)
	{
		if (id)
		{
			return Cookies.getJSON('flexrefresher.' + id);
		}
		return {};
	}
	
	function setUserOptions(id, options)
	{
		if (id)
		{
			Cookies.set('flexrefresher.' + id, options);
		}
	}

	/* FLEXREFRESHER PUBLIC CLASS DEFINITION
	 * ================================= */

	var FlexRefresher = function(element, options)
	{
		this.id = element.id;

		var userOptions = getUserOptions(this.id);
		
		this.options = $.extend({}, defaults, $.fn.flexRefresher.defaults, options, userOptions);

		this.options.delay = parseInt(this.options.delay || $.fn.flexRefresher.defaults.delay || defaults.delay, 10);
		this.options.delay *= 1000; //millis

		if(typeof this.options.paused == 'string')
		{
			this.options.paused = this.options.paused == 'true';
		}
		this.options.paused = !!this.options.paused;

		this.$element = $(element);
		this.$target = this.$element.find('[data-flex="target"]');
		this.$loading = this.$element.find('[data-flex="loading"]');
		this.$error = this.$element.find('[data-flex="error"]');

		this.init();
	};

	FlexRefresher.prototype = 
	{
		constructor: FlexRefresher,
		timer: undefined,

		init: function() 
		{
			if(this.options.uri && this.options.delay && this.$target.length != 0)
			{
				this.refresh();
			}
		},

		scheduleNext: function() 
		{
			if(this.options.paused || !!globallyDisabled)
			{
				return;
			} 
			
			clearTimeout(this.timer);
			this.timer = setTimeout($.proxy(this.refresh, this), this.options.delay);
		},

		setMax: function(max)
		{
			if (isNaN(parseInt(max, 10)))
			{
				return;
			}
			
			this.options.max = max;
			
			var userOptions = {
				max: this.options.max
			};
			
			setUserOptions(this.id, userOptions);
			
			this.refresh();
		},
			
		
		refresh: function() 
		{
			if(this.$target.hasClass('show'))
			{
				this.scheduleNext.apply(this);
				return;
			}
			
			if(this.$loading.length != 0) 
			{
				this.$target.html(this.$loading.html());
			}

			var uri = this.options.uri.replace("{max}", this.options.max);

			var self = this;
			this.$element.addClass(this.options.loadingClass);

			// bootstrap tooltip incompatibility, always hide all tooltips before
			// replacing content.
			this.$target.find('[data-toggle=tooltip]').each(function() 
			{
				var tt =  $(this).data('tooltip');
				if (tt)
				{
					tt.disable();
					tt.destroy();
				}
			});
			 
			this.$target.load(uri, function(response, status, xhr)
			{
				try
			{
				self.$element.removeClass(self.options.loadingClass);
				if(status == 'error')
				{
					if(self.$error.length != 0) 
					{
						self.$target.html(self.$error.html());
					}
				}
				}
				finally
				{
					self.scheduleNext.apply(self);
				}
			});
		},

		pause: function() 
		{
			this.options.paused = true;
			clearTimeout(this.timer);
		},

		resume: function()
		{
			this.options.paused = false;
			this.scheduleNext();
		},

		toggle: function()
		{
			this[this.options.paused ? 'resume' : 'pause']();
		}
	};

		
	/* FLEXREFRESHER PLUGIN DEFINITION
	 * =========================== */

	$.fn.flexRefresher = function(option)
	{
		return this.each(function()
		{
			var $this = $(this)
				, data = $this.data('flexrefresher')
				, options = typeof option === 'object' && option;

			if(!data)
			{
				$this.data('flexrefresher', (data = new FlexRefresher(this, options)));
			}
      
			if(typeof option === 'string') 
			{
				data[option]();
			}
		});
	};

	$.fn.flexRefresher.defaults = {
		delay: defaults.delay
	};

	$.fn.flexRefresher.Constructor = FlexRefresher;
	
	$.fn.flexRefresher.DisableAll = function() {
		globallyDisabled = true;
	};

	/* FLEXREFRESHER DATA-API
	* ================== */

	$(window).on('load', function() 
	{
		$('[data-flex="flexrefresher"]').each(function() 
		{
			var $this = $(this)
				, data = $this.data();

			$this.flexRefresher(data);
		});
	});

	$(document).on('click.flexrefresher.data-api', '[data-flex="refresh"]', function(e)
	{
		var $this = $(this)
			, href = $this.attr('href')
      		, $target = $($this.attr('data-target') || (href && href.replace(/.*(?=#[^\s]+$)/, ''))) //strip for ie7

		$target.flexRefresher('refresh');
	});

	$(document).on('click.flexrefresher.data-api', '[data-flex="max"]', function(e)
	{
		var $this = $(this)
			, href = $this.attr('href')
      		, $target = $($this.attr('data-target') || (href && href.replace(/.*(?=#[^\s]+$)/, ''))) //strip for ie7
      		, max = $this.data('max')
      	
      	var flexRefresher = $target.data('flexrefresher');
		flexRefresher.setMax(max);
	});

})(jQuery);