import swal from 'sweetalert2';
import toastr from 'toastr';

var PARAMETER_SEPARATOR = ',';
var swalButtonsSelector = '.btn[data-swal], .action[data-swal]';

$(function($) {
	bindSwals(swalButtonsSelector);
});

global.delayedBindSwals = function() {
	bindSwals(swalButtonsSelector);
}

function bindSwals(selector, parent) {
	if(!selector) return;
	var $parent = $(parent || document);
	$parent.ready(function() {
		var KEY = 'bindSwalSet@' + selector;
		if(!$parent.data(KEY)) {
			$parent.data(KEY, true);
			var fn = debounce(launchSwal, 0, true);
			$parent.on('click', selector, function(e) {
				e.preventDefault();
				fn.apply(this, arguments);
			});
		}
	});
};

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) 
		{
			func.apply(context, args);
		}
	};
};

function launchSwal(event) {
	var target = (event.currentTarget);
	var callbackId = $(target).attr('data-swal');
	var callback = callbackId.charAt(0).toUpperCase() + callbackId.substring(1);
	if(window['showSwal' + callback]) {
		window['showSwal' + callback].call(target, event);
	} else {
		event.preventDefault();
		toastr['error']('De functie "' + callback + '" heeft een fout veroorzaakt en is afgebroken.');
	}
};

function openSwal(event) {
	var target = (event.currentTarget);
	var title = $(target).data('title');
	var text = $(target).data('text');
	var icon = $(target).data('icon');
	
	swal({
		title: title,
		text: text,
		type: icon,
		confirmButtonText: 'Continue',
		buttonsStyling: false,
		confirmButtonClass: "btn btn-primary btn-large",
	});
}

window.showSwalDefaultHandler = function(event, data) {
	event.preventDefault();	
	openSwal(event);
};

window.showSwalGenericFormHandler = function(event, data) {
	event.preventDefault();
	
	let target = (event.currentTarget);
	let $target = $(target);
	let title = $target.data('title');
	let text = $target.data('text');
	let icon = $target.data('icon');
	let buttonText = $target.data('buttontext');
	let $form = $('#' + $target.data('form'));
	
	if($target.data('selectTable'))
	{
		let $selectTable = $($target.data('selectTable'));
		let selectedTableItems = $selectTable.find('input.select-table-checkbox:checked');
		
		if(selectedTableItems.length <= 0)
		{
			toastr["error"]("U heeft geen items geselecteerd");
			return
		}
		
		let selectedTableItemsIds = selectedTableItems.map(function() {
			return $(this).attr('id');
		}).get().join(',');
		
		$form.find('#selectTableField').val(selectedTableItemsIds);
	}
	
	let redirect = function(data, textStatus, jqXHR) {
		let uri = jqXHR.getResponseHeader("Location");
		window.location.replace(uri);
	};
	
	swal({
		title: title,
		text: text,
		type: icon,
		showCancelButton: true,
		confirmButtonText: buttonText,
		cancelButtonText: 'Cancel',
		buttonsStyling: false,
		confirmButtonClass: "btn btn-primary btn-large mx-1",
		cancelButtonClass: "btn btn-link btn-large mx-1",
		reverseButtons: true
	}).then((result) => {
		if (!$form.length) return;
		if (result.value) {
			$.ajax({
				url: $form.attr('action'),
				type: $form.attr('method'),
				data: $form.serialize(),
				headers: {'X-No-Redirect': true},
				statusCode: {
					//"201 Created"  "202 Accepted"  "204 No Content"
					201: redirect
				},
				success: function(data, textStatus, jqXHR) {
					swal.fire({
						title: "Loading, please wait...",
						onBeforeOpen: () => {
							swal.showLoading();
						}
					})
					if(jqXHR.status == 201) return; // handled by statusCode:201 fn.
				},
				error: function(jqXHR, error, errorThrown) {
					if(jqXHR.status == 400) {
						toastr["error"](jqXHR.responseJSON.message);
					}
					else {
						toastr["error"]("Something went wrong");
					}
				}
			});
		}
	});
}


window.showSwalRemoveDraft = function(event, data) {
	event.preventDefault();
	
	let target = (event.currentTarget);
	let $target = $(target);
	let title = $target.data('title');
	let text = $target.data('text');
	let icon = $target.data('icon');
	let buttonText = $target.data('buttontext');
	let $form = $('#' + $target.data('form'));
		
	let redirect = function(data, textStatus, jqXHR) {
		let uri = jqXHR.getResponseHeader("Location");
		window.location.replace(uri);
	};
	
	let removeDraftCard = function() {
		let $card = $($target.data('msgBox'));
		$card.slideUp(function() {
			$card.remove();
		});
	};
	
	swal({
		title: title,
		text: text,
		type: icon,
		showCancelButton: true,
		confirmButtonText: buttonText,
		cancelButtonText: 'Annuleren',
		buttonsStyling: false,
		confirmButtonClass: "btn btn-primary btn-large mx-1",
		cancelButtonClass: "btn btn-link btn-large mx-1",
		reverseButtons: true
	}).then((result) => {
		if (!$form.length) return;
		if (result.value) {
			removeDraftCard();
			$.ajax({
				url: $form.attr('action'),
				type: $form.attr('method'),
				data: $form.serialize(),
				headers: {'X-No-Redirect': true},
				statusCode: {
					//"201 Created"  "202 Accepted"  "204 No Content"
					//201: redirect
					201: function() {
						toastr["success"]("Het conceptbericht is verwijderd.");
					}
				},
				success: function(data, textStatus, jqXHR) {
					if(jqXHR.status == 201) return; // handled by statusCode:201 fn.
				},
				error: function(jqXHR, error, errorThrown) {
					if(jqXHR.status == 400) {
						toastr["error"](jqXHR.responseJSON.message);
					}
					else {
						toastr["error"]("Er ging iets fout");
					}
				}
			});
		}
	});
}