import toastr from 'toastr';
import Cookies from 'js-cookie';
import PerfectScrollbar from 'perfect-scrollbar';
import 'block-ui';

var PARAMETER_SEPARATOR = ',';
var drawerButtonsSelector = '.btn[data-drawer], .action[data-drawer], a[data-drawer]';

$(function($) {
	bindDrawers(drawerButtonsSelector);
	
	toastr.options = {
		"closeButton": true,
		"debug": false,
		"newestOnTop": true,
		"progressBar": true,
		"positionClass": "toast-top-right",
		"preventDuplicates": false,
		"onclick": null,
		"showDuration": "300",
		"hideDuration": "1000",
		"timeOut": "5000",
		"extendedTimeOut": "1000",
		"showEasing": "swing",
		"hideEasing": "linear",
		"showMethod": "fadeIn",
		"hideMethod": "fadeOut"
	};
	
	$.blockUI.defaults = {
		css: {
			top: 0,
			border: 'unset',
			backgroundColor: 'unset'
		}
	};
});

global.delayedBindDrawers = function() {
  bindDrawers(drawerButtonsSelector);
}

function bindDrawers(selector, parent) {
	if(!selector) return;
	var $parent = $(parent || document);	
	$parent.ready(function() {		
		var KEY = 'bindDrawerSet@' + selector;
		if(!$parent.data(KEY)) {
			$parent.data(KEY, true);
			var fn = debounce(launchDrawer, 0, true);
			$parent.on('click', selector, function(e) {
				e.preventDefault();
				fn.apply(this, arguments);
			});
		}
	});
};

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) {
			func.apply(context, args);
		}
	};
};

function launchDrawer(event) {
	var target = (event.currentTarget);	
	var callbackId = $(target).attr('data-drawer');
	var callback = callbackId.charAt(0).toUpperCase() + callbackId.substring(1);
	
	if(window['showDrawer' + callback]) {
		window['showDrawer' + callback].call(target, event);
	} else {
		event.preventDefault();
		toastr['error']("Drawer function error.");
	}
};

function openFormDrawer(button) {
	var drawerId = $(button).attr('data-drawer');
	
	$(drawerButtonsSelector).each(function() {
		$(this).removeClass('disabled');
	});
	
	$(button).addClass('disabled');
	$(button).tooltip('hide');
	var drawer = $('#' + drawerId);
	drawer.addClass('drawer-open');
};


function openDrawerFailed(button) {
	var drawerId = $(button).attr('data-drawer');
	toastr['error']("Drawer function error: " + drawerId);
}

function closeFormDrawer(button) {
	var drawerId = $(button).closest('.side-drawer__inner').parent().attr('id');
	$("#" + drawerId).removeClass('drawer-open');
	
	//closes all popovers
	$('.popover').popover('hide');
	$('#smartwizard3').unblock();
	
	$(drawerButtonsSelector).each(function () {
		if ($(this).attr('data-drawer') == drawerId) {
			$(this).removeClass('disabled');
		}
	});
}

window.showDrawerDefaultHandler = function(event, data) {
	var button = this;
	if ($(button).hasClass('disabled')) return;
	var contentId = $(button).attr('data-drawer');
	if(!contentId) return;

	//Temporary solution, need to brainstorm about best practice for what should happen when user opens drawer when one is already opened.
	var continueDrawer = true;
	$('#formDrawerWrapper').find('.side-drawer-form.drawer-open').each(function() {
		if ($(this).attr('id') != contentId) {
			// TODO: i18n
			continueDrawer = confirm("Are you sure you want to open a new drawer?");
			return;
		}
	});
	if (!continueDrawer) return;

	event.preventDefault();	

	var url = $(button).attr('data-url');

	$.ajax({
		url: url,
		data: data || {},
		type: 'GET', 
		dataType: 'html', 
		cache: false,
		success : function(data) {
			$('#formDrawerWrapper').html(data);
			openFormDrawer(button);
			$('.side-drawer-form').find('[data-toggle="tooltip"]').tooltip();
			$('.side-drawer-form').on('click', '.close-drawer-btn', function () {
				closeFormDrawer(this);
			})
			
			const ps = new PerfectScrollbar('#' + contentId + ' .side-drawer__inner', {
				wheelSpeed: 2,
				wheelPropagation: false,
				minScrollbarLength: 20
			});
			
			ps.update();
			
			if ($('#toggleSearch').hasClass('active')) {
				$('#toggleSearch').click();
			};
			
			if ($('#toggleNav').hasClass('is-active')) {
				$('#toggleNav').click();
			};
		},
		error: function(data) {
			//console.log(data.status);
			openDrawerFailed(button);
		} 
	});
};

window.showDrawerListingHandler = function(event) {
	var contentId = $(this).attr('data-drawer');
	if(!contentId) return;
	
	event.preventDefault();	
	
	var $frm = $('#listingForm');
	var selectedRows = $frm.find('tbody input.select-table-checkbox:checked');
	
	if(selectedRows.length <= 0) {
		toastr['error']("No rows selected");
		return;
	}
	
	var selectedRowsIds = selectedRows.map(function() {
		return $(this).attr('id');
	}).get().join(PARAMETER_SEPARATOR);
	
	var data = { ids: selectedRowsIds };
	
	showDrawerDefaultHandler.call(this, event, data);
};

var redirect = function(contentId)
{
	return function(data, textStatus, jqXHR)
	{
		var uri = jqXHR.getResponseHeader("Location");
		window.location.replace(uri);
	};
}

var displayDrawer = function(contentId)
{
	return function(data, textStatus, jqXHR)
	{
		if (jqXHR && jqXHR.status == 201)
		{
			return; // handled by statusCode:201 fn.
		}
		
		if (data)
		{

			$('#formDrawerWrapper').html(data);
			//$('#' + contentId).find('.side-drawer__inner').html(data);
			$('#' + contentId).find('[data-toggle="tooltip"]').tooltip();
			$('#' + contentId).find('.close-drawer-btn').on('click', function () {
				closeFormDrawer(this);
			});
			
			const ps = new PerfectScrollbar('#' + contentId + ' .side-drawer__inner', {
				wheelSpeed: 2,
				wheelPropagation: false,
				minScrollbarLength: 20
			});
			
			ps.update();
		} 
		else
		{
			// ???
		}
	};
}

var displayError = function(contentId)
{
	return function(jqXHR, textStatus, errorThrown)
	{
		unblockAsyncForm(contentId);
		toastr['error']("No connection, please refresh and try again.");
	};
};


function blockAsyncForm(contentId)
{
	$('#' + contentId).block({ message: 
		'<div class="loader mx-auto">' + 
			'<div class="ball-pulse">' + 
				'<div class="bg-white"></div>' + 
				'<div class="bg-white"></div>' + 
				'<div class="bg-white"></div>' + 
			'</div>' +
		'</div>'
	});
}

function unblockAsyncForm(contentId)
{
	$('#' + contentId).unblock();
}

global.installAsyncForm2 = function(contentId, options)
{
	options = options || {};

	var drawerId = '#' + contentId + 'Drawer';
	var formId = '#' + contentId + 'Form';

	var asyncFormHander2 = function(event)
	{
		if(typeof options.submit == 'function')
		{
			var ret = options.submit.apply(this, arguments);
			
			if(ret === false) 
				return false;
			
			if(event.isDefaultPrevented()) 
				return;
			
			delete options.submit;
		}
		
		event.preventDefault();
	
		var $drawer = $(drawerId);
		var $form = $drawer.find(formId);
		
		blockAsyncForm(contentId);
		
		var ajaxOptions = $.extend({
			type: 'POST',
			url: $form.attr('action'),
			data: $form.serialize(),
			headers: {'X-No-Redirect': true},
			success: displayDrawer(contentId),
			statusCode: {
				//"201 Created"  "202 Accepted"  "204 No Content"
				201: redirect(contentId)
			},
			error: displayError(contentId)
		}, options);
		
		$.ajax(ajaxOptions);
	};
		
	jQuery(function($) {
		var $form = $(drawerId).find(formId);
		$form.on('submit', asyncFormHander2);
		if(drawerId == "#submitReportDrawer") {
			$('#smartwizard3').block({message: '<div class="loader bg-transparent no-shadow p-0"><div class="ball-grid-pulse"></div></div>'});
		}
	});
}

global.installOnChangeStream = function(contentId, drawer, defaultOptionsId)
{
	console.log('installOnChangeStream(%o, %o, %o)',contentId, drawer, defaultOptionsId);

	var drawerId = '#' + contentId + 'Drawer';
	var formId = '#' + contentId + 'Form';
	

	$(drawerId).find(formId + ' select[name = streamCode]').change(function()
	{
		var $select = $(this);
		var $users = $(drawerId).find(formId + ' select[name = assignee]');
		var selectedUser = $users.val();
		var url = $(drawerId).find(formId + ' :input[name = accesstoUri]').val();
		var data = {
			streams: $select.val()
		};
		
		function processUsers(data) {
			if(data)
			{
				$users.find('option:not(.me)').remove();
				$users.append(data);
				$users.val(selectedUser); //restore selected user
				if($users.find('option:selected').length == 0)
				{
					$users.find('option.me').attr('selected', 'selected');
				}
			}
		}
		
		if ($select.val() == ""){
			var data = $(defaultOptionsId).find('option');
			processUsers(data);
			return;
		}

		$.ajax(
		{
			type: 'GET',
			url: url,
			data: data,
			headers: {'X-No-Redirect': true},
			success: function(data, textStatus, jqXHR)
			{
				//pw.hide();
				//PleaseWait.hide();

				//console.log('success');
				// new html received

				processUsers(data);
			},
			error: function(jqXHR, textStatus, errorThrown)
			{
				//pw.hide();
				//PleaseWait.hide();
				toastr['error']("No connection, please refresh and try again.");
				//console.log('error: this %o', this);
				//console.log('error: %o', arguments);
			}
		});
	}).change(function()
	{
		var $select = $(this);
		var $priority = $(drawerId).find(formId + ' select[name = priority]');
		if($priority.length == 0 || $priority.data('manuallySet'))
		{
			return;
		}

		//var selectedPriority = $priority.val();

		var url = $(drawerId).find(formId + ' :input[name = streamJsonUri]').val();
		var params = {
			code: $select.val()
		};
		
		$.ajax(
		{
			dataType: 'json',
			url: url,
			data: params,
			//headers: {'X-No-Redirect': true},
			success: function(streamBean, textStatus, jqXHR)
			{
				// data = simpleStreamBean in javascript
				$priority.val(streamBean.defaultPriority);
			},
			error: function(jqXHR, textStatus, errorThrown)
			{
				toastr['error']("No connection, please refresh and try again.");
				//console.log('error: this %o', this);
				//console.log('error: %o', arguments);
			}
		});
		
	});
	
	$(drawerId).find(formId + ' select[name = priority]').change(function() 
	{
		$(this).data('manuallySet', true);
	});
}


global.installAsyncUpload2 = function(contentId, options)
{
	options = options || {};

	var dialogId = '#' + contentId + 'Drawer';
	var formId = '#' + contentId + 'Form';

	var $form = $(formId);
	var $box = $form.find('.uploader-box');
	var endpoint = $form.prop('action');
	var maxFileSize = options.maxFileSize || null;

	//var dragDropTarget = options.dragDropTarget || formId + ' .drawer-body';
	
	// feature detection for drag&drop upload
	var isAdvancedUpload = function() {
		var div = document.createElement('div');
		return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) 
			&& 'FormData' in window && 'FileReader' in window;
	}();


	var $input		 = $form.find('input[type="file"]');
	var $label		 = $form.find('.box__label');
	var $errorMsg	 = $form.find('.box__error span');
	var droppedFiles = false;
	var isRequired	 = !!$input.prop('required');
	$input.removeAttr('required');
	
	var showFiles = function(files)
	{
		$label.text( files.length > 1 ? ( $input.attr( 'data-multiple-caption' ) || '' ).replace( '{count}', files.length ) : files[ 0 ].name );
	};

	// letting the server side to know we are going to make an Ajax request
	$form.append('<input type="hidden" name="ajax" value="true" />');

	// automatically submit the form on file select
	$input.on('change', function(e) 
	{
		showFiles(e.target.files);
	});

	
	// drag&drop files if the feature is available
	if (isAdvancedUpload)
	{
		$box.find('.custom-file').removeClass('custom-file');
		$box.find('.custom-file-input').removeClass('custom-file-input');
		
		$box
		.addClass('advanced') // letting the CSS part to know drag&drop is supported by the browser
		.on('drag dragstart dragend dragover dragenter dragleave drop', function(e)
		{
			// preventing the unwanted behaviours
			e.preventDefault();
			e.stopPropagation();
		})
		.on('dragover dragenter', function()
		{
			$box.addClass('is-dragover');
		})
		.on('dragleave dragend drop', function()
		{
			$box.removeClass('is-dragover');
		})
		.on('drop', function(e)
		{
			// the files that were dropped
			droppedFiles = e.originalEvent.dataTransfer.files;
			showFiles(droppedFiles);
		});
	}


	// if the form was submitted

	$form.on('submit', function(e)
	{
//		if (typeof(options.onStart) == 'function') 
//		{
//			options.onStart(contentId);
//		}

		let fileTooBig = false;
		let hasFiles = false;
		
		$input.each(function()
		{
			let files = this.files; 
			for (let i = 0; i < files.length; i++)
			{
				let file = files[i];
				hasFiles = true;
				if (maxFileSize) 
				{
					if (file.size > maxFileSize)
					{
						fileTooBig = file;
					}
				}
			}
		});
		
		if (droppedFiles)
		{
			let files = droppedFiles;
			for (let i = 0; i < files.length; i++)
			{
				let file = files[i];
				hasFiles = true;
				if (maxFileSize) 
				{
					if (file.size > maxFileSize)
					{
						fileTooBig = file;
					}
				}
			}
		}
		
		if (isRequired && !hasFiles)
		{
			e.preventDefault();
			toastr['error']("No file selected");
			return false;
		}
		
		if (fileTooBig)
		{
			e.preventDefault();
			var fileSizeInMB = Math.ceil(file.size/1024/1024); 
			var maxFileSizeInMB = Math.round(maxFileSize/1024/1024);
			toastr['error']("File too big: " + fileTooBig.name + " size: " + fileSizeInMB + " max size: " + maxFileSizeInMB);
			return;
		}
		
		
		// preventing the duplicate submissions if the current one is in progress
		if ($box.hasClass('is-uploading'))
		{
			return false;
		}

		if (isAdvancedUpload) 
		{
			// ajax file upload for modern browsers

			$box.addClass('is-uploading').removeClass('is-error');
			
			e.preventDefault();

			// gathering the form data
			var ajaxData = new FormData($form.get(0));
			if (droppedFiles)
			{
				$.each(droppedFiles, function(i, file)
				{
					ajaxData.append($input.attr('name'), file);
				});
			}

			blockAsyncForm(contentId);

			// ajax request
			$.ajax(
			{
				url: 			$form.attr('action'),
				type:			$form.attr('method'),
				data: 			ajaxData,
				dataType:		'html',
				cache:			false,
				contentType:	false,
				processData:	false,
				headers: 		{'X-No-Redirect': true},
				success: displayDrawer(contentId),
				statusCode: {
					//"201 Created"  "202 Accepted"  "204 No Content"
					201: redirect(contentId)
				},
				error: displayError(contentId)
			});
		}
		else 
		{
			// fallback Ajax solution upload for older browsers

			blockAsyncForm(contentId);
			$box.addClass('is-uploading').removeClass('is-error');
			
			var iframeName = 'uploadiframe' + new Date().getTime();
			var $iframe = $('<iframe name="' + iframeName + '" style="display: none;"></iframe>');

			$('body').append($iframe);
			$form.attr('target', iframeName);

			$iframe.one('load', function()
			{
				//var data = $.parseJSON( $iframe.contents().find('body').text() );
				var data = $iframe.contents().find('body').html();
				//Remove?
				//unblockAsyncForm(contentId);
				$box.removeClass('is-uploading');
				$box.removeAttr('target');
				displayDrawer(contentId)(data, {}, {});
				$iframe.remove();
			});
		}
	});


	// Firefox focus bug fix for file input
	$input
		.on('focus', function() { $input.addClass('has-focus'); })
		.on('blur', function() { $input.removeClass('has-focus'); });
};



global.installAsyncMessageForm = function(contentId, options)
{
	options = options || {};

	let mainId = '#' + contentId;
	let formId = '#' + contentId + 'Form';

	let autoSaveTimer = null;
	let autoSaveInterval = 60000;
	
	function autoSaveDraft() 
	{
		let $form = $(mainId).find(formId);
		let isBlocked = $form.data('isSubmitting'); 
		if (isBlocked)
		{
			return;
		}
		
		let $text = $form.find('#' + contentId + 'Editor');
		
		let lastData = $form.data('lastData');
		let data = $form.serialize();
		
		let isDirty = lastData != null && lastData != data;
		
		// This should also guard autosaveAction against concurrent auto-saves.
		$form.data('lastData', data);
			
		if (isDirty) {
	
			var url = $form.data('autosaveAction');

			$.ajax({
				type: 'POST',
				url: url,
				data: data,
				headers: {'X-No-Redirect': true},
				success: function(data, textStatus, jqXHR) {
					$form.find('.footer-status').html(data);
				}
			});
		}
	}
	
	var displayMessageError = function(contentId)
	{
		var fnError = displayError(contentId);		
		return function(jqXHR, textStatus, errorThrown) 
		{
			fnError(jqXHR, textStatus, errorThrown);
			unblockAsyncForm(contentId);
		};
	}
}

global.installAsyncMessageAttachmentUpload = function(contentId, options)
{
	options = options || {};

	var dialogId = '#' + contentId + 'Drawer';
	var formId = '#' + contentId + 'Form';

	var $form = $(formId);
	var $box = $form.find('.uploader-box');
	var endpoint = $form.prop('action');
	var maxFileSize = options.maxFileSize || null;

	//var dragDropTarget = options.dragDropTarget || formId + ' .drawer-body';
	
	// feature detection for drag&drop upload
	var isAdvancedUpload = function() {
		var div = document.createElement('div');
		return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) 
			&& 'FormData' in window && 'FileReader' in window;
	}();


	var $input		 = $form.find('input[type="file"]');
	var $label		 = $form.find('.box__label');
	var $errorMsg	 = $form.find('.box__error span');
	var droppedFiles = false;
	
	var showFiles = function(files)
	{
		$label.text( files.length > 1 ? ( $input.attr( 'data-multiple-caption' ) || '' ).replace( '{count}', files.length ) : files[ 0 ].name );
	};

	// letting the server side to know we are going to make an Ajax request
	$form.append('<input type="hidden" name="ajax" value="true" />');

	// automatically submit the form on file select
	$input.on('change', function(e) 
	{
		showFiles(e.target.files);
	});

	
	// drag&drop files if the feature is available
	if (isAdvancedUpload)
	{
		$box.find('.custom-file').removeClass('custom-file');
		$box.find('.custom-file-input').removeClass('custom-file-input');
		
		$box
		.addClass('advanced') // letting the CSS part to know drag&drop is supported by the browser
		.on('drag dragstart dragend dragover dragenter dragleave drop', function(e)
		{
			// preventing the unwanted behaviours
			e.preventDefault();
			e.stopPropagation();
		})
		.on('dragover dragenter', function()
		{
			$box.addClass('is-dragover');
		})
		.on('dragleave dragend drop', function()
		{
			$box.removeClass('is-dragover');
		})
		.on('drop', function(e)
		{
			// the files that were dropped
			droppedFiles = e.originalEvent.dataTransfer.files;
			showFiles(droppedFiles);
		});
	}


	// if the form was submitted

	$form.on('submit', function(e)
	{
//		if (typeof(options.onStart) == 'function') 
//		{
//			options.onStart(contentId);
//		}
		
		let hasDossierAttachmentsSelected = $form.find('input[name="dossierAttachments"]:checked').length > 0;
		let hasMessageAttachmentsSelected = $form.find('input[name="dossierMessagesAttachments"]:checked').length > 0;

		let fileTooBig = false;
		let hasFiles = false;
		
		
		$input.each(function()
		{
			let files = this.files; 
			for (let i = 0; i < files.length; i++)
			{
				let file = files[i];
				hasFiles = true;
				if (maxFileSize) 
				{
					if (file.size > maxFileSize)
					{
						fileTooBig = file;
					}
				}
			}
		});
		
		if (droppedFiles)
		{
			let files = droppedFiles;
			for (let i = 0; i < files.length; i++)
			{
				let file = files[i];
				hasFiles = true;
				if (maxFileSize) 
				{
					if (file.size > maxFileSize)
					{
						fileTooBig = file;
					}
				}
			}
		}
		
		if (!hasDossierAttachmentsSelected && !hasMessageAttachmentsSelected && !hasFiles)
		{
			e.preventDefault();
			toastr['error']("No file selected");
			return false;
		}
		
		if (fileTooBig)
		{
			e.preventDefault();
			var fileSizeInMB = Math.ceil(file.size/1024/1024); 
			var maxFileSizeInMB = Math.round(maxFileSize/1024/1024);
			toastr['error']("File too big: " + fileTooBig.name + " size: " + fileSizeInMB + " max size: " + maxFileSizeInMB);
			return;
		}
		
		
		// preventing the duplicate submissions if the current one is in progress
		if ($box.hasClass('is-uploading'))
		{
			return false;
		}

		if (isAdvancedUpload) 
		{
			// ajax file upload for modern browsers

			$box.addClass('is-uploading').removeClass('is-error');
			
			e.preventDefault();

			// gathering the form data
			var ajaxData = new FormData($form.get(0));
			if (droppedFiles)
			{
				$.each(droppedFiles, function(i, file)
				{
					ajaxData.append($input.attr('name'), file);
				});
			}

			blockAsyncForm(contentId);

			// ajax request
			$.ajax(
			{
				url: 			$form.attr('action'),
				type:			$form.attr('method'),
				data: 			ajaxData,
				dataType:		'html',
				cache:			false,
				contentType:	false,
				processData:	false,
				headers: 		{'X-No-Redirect': true},
				success: displayDrawer(contentId),
				statusCode: {
					//"201 Created"  "202 Accepted"  "204 No Content"
					201: redirect(contentId)
				},
				error: displayError(contentId)
			});
		}
		else 
		{
			// fallback Ajax solution upload for older browsers

			blockAsyncForm(contentId);
			$box.addClass('is-uploading').removeClass('is-error');
			
			var iframeName = 'uploadiframe' + new Date().getTime();
			var $iframe = $('<iframe name="' + iframeName + '" style="display: none;"></iframe>');

			$('body').append($iframe);
			$form.attr('target', iframeName);

			$iframe.one('load', function()
			{
				//var data = $.parseJSON( $iframe.contents().find('body').text() );
				var data = $iframe.contents().find('body').html();
				//Remove ?
				//unblockAsyncForm(contentId);
				$box.removeClass('is-uploading');
				$box.removeAttr('target');
				displayDrawer(contentId)(data, {}, {});
				$iframe.remove();
			});
		}
	});


	// Firefox focus bug fix for file input
	$input
		.on('focus', function() { $input.addClass('has-focus'); })
		.on('blur', function() { $input.removeClass('has-focus'); });
};