// Imports
import $ from "jquery";
import 'bootstrap';
import 'metismenu';
import PerfectScrollbar from 'perfect-scrollbar';
import Chart from 'chart.js';
import toastr from 'toastr';
import Cookies from "js-cookie";
import Swal from "sweetalert2"
import resizable from "webpack-jquery-ui/resizable";
import "webpack-jquery-ui/sortable";
import "jquery-ui-touch-punch";
import NProgress from 'multi-nprogress';
import shortAndSweet from 'short-and-sweet';

// Themes

import './assets/default.scss';

global.$ = $;
global.jQuery = $;
global.Chart = Chart;
global.toastr = toastr;
global.Cookies = Cookies;
global.resizable = resizable;
global.Swal = Swal;
global.shortAndSweet = shortAndSweet;
//global.jTest = $;


const nprogress = new NProgress();

(function() {
	nprogress.configure({ 
		showSpinner: false
		//, parent: '.app-header'
	});
	
	let activeRequests = 0;
	
	function nStart() {
		if (activeRequests === 0) {
			nprogress.start();
		}
		activeRequests++;
	}
	
	function nDone() {
		activeRequests--;
		if (activeRequests === 0) {
			nprogress.done();
		}
	}
	
	$(document).ajaxStart(nStart);
	$(document).ajaxStop(nDone);

	$(window).on('beforeunload', nStart);
})();

$(document).ready(() => {

	//PerfectNotificationScrollbar Initializer
	window.PerfectNotificationScrollbarHandler = function(container)
	{
		const ps = new PerfectScrollbar(container, {
			wheelSpeed: 2,
			wheelPropagation: false,
			minScrollbarLength: 20
		});
	}
	
    // Sidebar Menu

//    setTimeout(function () {
        $(".vertical-nav-menu").metisMenu();
//    }, 100);

    // Search wrapper trigger

    $('.search-icon').click(function () {
    	let $grandparent = $(this).parent().parent();
    	
    	if($grandparent.hasClass('active'))
		{
    		$('#quickSearchForm').submit();
		}
    	else
		{
    		$grandparent.addClass('active');
    		$grandparent.find('#quickSearchInput').focus();
		}
    });

//    $('.search-wrapper .close').click(function () {
//        $(this).parent().removeClass('active');
//    });


    // BS4 Popover

//    $('[data-toggle="popover-custom-content"]').each(function (i, obj) {
//
//        $(this).popover({
//            html: true,
//            placement: 'auto',
//            template: '<div class="popover popover-custom" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
//            content: function () {
//                var id = $(this).attr('popover-id');
//                return $('#popover-content-' + id).html();
//            }
//        });
//
//    });

    // Stop Bootstrap 4 Dropdown for closing on click inside

    $('.dropdown-menu').on('click', function (event) {
        if (!$(this).hasClass("close-on-click")) //Fix for opening drawer from dropdown
        {
            var events = $._data(document, 'events') || {};
            events = events.click || [];
            for (var i = 0; i < events.length; i++) {
                if (events[i].selector) {
                    if ($(event.target).is(events[i].selector)) {
                        events[i].handler.call(event.target, event);
                    }
                    $(event.target).parents(events[i].selector).each(function () {
                        events[i].handler.call(this, event);
                    });
                }
            }
            event.stopPropagation(); //Always stop propagation
        }
    });



    $('body').on('click', function (e) {
        $('[rel="popover-focus"]').each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });


    // BS4 Tooltips

    function activeTooltip(e) {
    	$('[data-toggle="tooltip"]').tooltip();
    	$('[data-toggle="tooltip-light"]').tooltip({
            template: '<div class="tooltip tooltip-light"><div class="tooltip-inner"></div></div>'
        });
    }
    
    $(activeTooltip);
    $(document).ajaxComplete(activeTooltip);
    
    // Drawer

    $('.open-right-drawer').click(function () {
        $(this).addClass('is-active');
        $('.app-drawer-wrapper').addClass('drawer-open');
        $('.app-drawer-overlay').removeClass('d-none');
    });

    $('.drawer-nav-btn').click(function () {
        $('.app-drawer-wrapper').removeClass('drawer-open');
        $('.app-drawer-overlay').addClass('d-none');
        $('.open-right-drawer').removeClass('is-active');
    });

    $('.app-drawer-overlay').click(function () {
        $(this).addClass('d-none');
        $('.app-drawer-wrapper').removeClass('drawer-open');
        $('.open-right-drawer').removeClass('is-active');
    });

    $('.mobile-toggle-nav').click(function () {
        $(this).toggleClass('is-active');
        $('.app-container').toggleClass('sidebar-mobile-open');
    });

    $('.mobile-toggle-header-nav').click(function () {
        $(this).toggleClass('active');
        $('.app-header__content').toggleClass('header-mobile-open');
    });

    $('.mobile-app-menu-btn').click(function () {
        $('.hamburger', this).toggleClass('is-active');
        $('.app-inner-layout').toggleClass('open-mobile-menu');
    });

    $(document).on('click', '[data-hiding]', function(e) {
    	let $this = $(e.target);
    	let target = $this.data('hiding');
    	$(target).slideToggle().toggleClass('hide');
    	if (!$this.data('keepSelf')) {
    		$this.toggleClass('hide', !$(target).hasClass('hide'));
    	}
    });

    function clearForm(form) {
        const frm_elements = form.elements;
        for (let i = 0; i < frm_elements.length; i++)
        {
            let field_type = frm_elements[i].type.toLowerCase();
            switch (field_type)
            {
                case "text":
                case "password":
                case "textarea":
                case "hidden":
                    frm_elements[i].value = "";
                    break;
                case "radio":
                case "checkbox":
                    if (frm_elements[i].checked)
                    {
                        frm_elements[i].checked = false;
                    }
                    break;
                case "select-one":
                case "select-multiple":
                    frm_elements[i].selectedIndex = -1;
                    break;
                default:
                    break;
            }
        }
        form.submit()
    }
	// Find all collapsebles and register a click event. When clicked, check if there is a 
	// flexrefresher parent and pause it. This will prevent the refresh (and re-collapse) of 
	// an expanded collapse (where the user is searching for an specific item).
    function collapsePauseRefresher() {
		let $collapse = $(this);
		let $flexrefresher = $collapse.parents('[data-flex="flexrefresher"]');
		$flexrefresher.flexRefresher('pause');
	} 
    
	$(document).on('click', '[data-toggle="collapse"]', function() {
		let $collapse = $($(this).data('target'));
		$collapse.on('show.bs.collapse', collapsePauseRefresher);
	});

    shortAndSweet('input', {counterClassName: 'float-right', counterLabel: '{length}/{maxlength}'})
    shortAndSweet('textarea', {counterClassName: 'float-right', counterLabel: '{length}/{maxlength}'})

    let paused = false;
    const loginPing = setInterval(function() {
            if (!paused && window.location.href.indexOf('login') == -1) {
                $.ajax({
                    type: "GET",
                    url: window.location.origin + "/ping",
                    error: function () {
                        paused = true;
                        Swal.fire({
                            icon: 'error',
                            title: 'Logged out',
                            text: "You've been logged out due to inactivity. To continue please log back in",
                            confirmButtonText: '<a class="text-white" onclick="window.location.reload()">Log in</a>',
                        })
                    }
                })
            }
        }
    , 60000);
});

